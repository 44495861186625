import { EnquiryType } from "@/clients/clients";

export default function enquiryTypeLabelMapper(type: EnquiryType): string {
  switch (type) {
    case EnquiryType.None:
      return "None";
    case EnquiryType.PatientEvaluation:
      return "PEP Requests";
    case EnquiryType.Skintelligence:
      return "Skintelligence Requests";
    case EnquiryType.Exhibition:
      return "Exhibitions";
    case EnquiryType.HcpEnquiry:
      return "HCP Enquiry";
    case EnquiryType.PatientEnquiry:
      return "Patient Enquiry";
    case EnquiryType.DemoDevice:
      return "Demo Device Requests";
    case EnquiryType.OptIn:
      return "Opt-ins";
    case EnquiryType.CostCalculator:
      return "Cost Calculator Requests";
    case EnquiryType.Spatula:
      return "Spatula Requests";
    default:
      return "";
  }
}
